.wholecontact {
  color: wheat;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact {
  max-width: 450px;
}
