.portfolioObject {
  max-width: 450px;
}

.result {
  font-size: larger;
  margin: 10px 0px 15px 0px;
}


.List {
  margin: 10px 0px 0px -25px;
}

.ListItem {
  margin: 10px 0px 0px 0px;
}