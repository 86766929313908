@media screen and (min-width: 550px) AND
(max-width: 1150px) {
.DemoCarousel {
  margin: 1% 1% 0% 1%;
  padding: 0% 10% 0% 10%;
}
}


@media screen and (min-width: 1150px) {
.DemoCarousel {
  margin: 2% 10% 0% 10%;
  padding: 0% 18% 0% 18%;
}
}